import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getLang from "../helpers/getLang";
import arrow_right from "../assets/images/icons/arrow_right.svg";
import google from "../assets/images/icons/google-icon.svg";
import apple from "../assets/images/icons/apple-icon.svg";
import outlook from "../assets/images/icons/outlook.svg";
import footer_hero from "../assets/images/cortar_footer_interna_desktop.webp";
import footer_hero_mobile from "../assets/images/cortar_footer_interna_mobile.webp";
import { Header } from "../components/header";
import { getIcsLink } from "../services/sportsService";
import { Footer } from "../components/footer";

export interface Props {
}

const Thanks: React.FC<Props> = () => {

    const [googleLink, setGoogleLink] = useState<string>("");
    const [icsJogos, setIcsJogos] = useState<string>("");
    const [selectedEvent, setSelectedEvent] = useState<{
        sport: string,
        date: string,
        match: string,
        icon: string,
        id: number,
    }>()


    useEffect(() => { window.scrollTo(0, 0); }, [])
    useEffect(() => {
        const sEvt = localStorage.getItem("sportSkidunCalendario");
        if (sEvt) {
            setSelectedEvent(JSON.parse(sEvt) || {})
        }
        setGoogleLink(localStorage.getItem("googleLinkSkidunCalendario") || "");
        setIcsJogos(localStorage.getItem("icsJogosSkidunCalendario") || "")
    }, [])

    const appleLink = async () => {
        const l = await getIcsLink(icsJogos);
        window.open("data:text/calendar;charset=utf8," + escape(l));
    }

    return (
        <>
            <Header />
            <main className="thanksMain">
                {selectedEvent ? <>
                    <div className="container">
                        <h2>Adicione o jogo abaixo selecionando o seu calendário</h2>
                        <div className="cardSport">
                            <img src={require(`../assets/images/icons/sports/${selectedEvent?.icon}`)} alt={selectedEvent?.sport} />
                            <span>{selectedEvent?.sport}</span>
                        </div>
                        <div className="infos">
                            <big>{selectedEvent.match}</big><br />
                            <span>{selectedEvent.date}</span>
                        </div>

                        <div className="addToCalendarButtons">
                            <a href={googleLink} className="google" target="_blank" rel="noreferrer">
                                <img src={google} alt="Google" />
                                <span>Adicionar ao Google</span>
                            </a>

                            <button onClick={appleLink} className="outlook">
                                <img src={outlook} alt="outlook" />
                                <span>Adicionar ao Outlook</span>
                            </button>
                            <button onClick={appleLink} className="apple">
                                <img src={apple} alt="Apple" />
                                <span>Download ICS</span>
                            </button>
                        </div>

                        <Link className="backlink" to="/"><img src={arrow_right} alt="" aria-hidden="true" /> Voltar para a página Inicial</Link>

                    </div>

                </> : <>
                    <div className="container">
                        <h2>Nenhum evento selecionado</h2>
                    </div></>}
                {/* <img src={footer_hero} alt="Hero" className="heroImg desktop" />
                <img src={footer_hero_mobile} alt="Hero" className="heroImg mobile" /> */}
            </main>
            <Footer />
        </>
    );
};

export { Thanks };
