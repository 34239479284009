import React from "react";
import skd_logo from "../assets/images/logo-skidun-full-color.svg";
// import fb_logo from "../assets/images/icons/facebook.svg";

export interface Props {
}

const Footer: React.FC<Props> = () => {
    return (
        <footer>
            <div className="container">
                <h6 className="logo">
                    <span>Criação e Desenvolvimento</span>
                    <a target="_blank" href="https://skidun.com/?utm_source=agenda_sportv&utm_medium=logo_footer&utm_campaign=calendario_paris_2024" rel="noreferrer">
                        <img src={skd_logo} alt="Skidun" />
                    </a>
                    {/* <Link to="/">
                        <img src={skd_logo} alt="Skidun" />
                    </Link> */}
                </h6>
            </div>
        </footer>
    );

};

export { Footer };
