import React, { useState } from "react";
import getLang from "../helpers/getLang";
import skidun_logo_big from "../assets/images/sportv-logo.svg";
import operadoras from "../assets/images/operadoras.png";
import closeModal from "../assets/images/icons/close_lighbox_button.png";
import { Link } from "react-router-dom";

export interface Props {
}

const Header: React.FC<Props> = () => {
    const lang = getLang();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    return (
        <>
            <header className="mainHeader">
                <div className="container">
                    <div className="topLink">
                        <span className="moperadoras hidedesk"><span onClick={() => setModalOpen(true)} >Operadoras</span></span>
                        <h1><Link to="/"><img src={skidun_logo_big} alt="sportv" width={160} /></Link></h1>
                        <span className="moperadoras hidemob"><span onClick={() => setModalOpen(true)} >Operadoras</span></span>
                    </div>
                    <h2 dangerouslySetInnerHTML={{ __html: lang['headline'] }}></h2>
                </div>
            </header>
            {modalOpen &&
                <div className="modalOperadora">
                    <div className="overlay" onClick={() => setModalOpen(false)}>

                    </div>
                    <div className="modalBody">
                        <div onClick={() => setModalOpen(false)} className="closeModal">
                            <img src={closeModal} alt="" />
                        </div>
                        <img src={operadoras} alt="Operadoras sportv" />
                    </div>
                </div>
            }
        </>
    );

};

export { Header };
